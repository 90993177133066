import axios from "axios"
import { useState } from "react"
import { useEffect } from "react"
import {  Link, Navigate, useParams } from "react-router-dom"
import { makeRequest } from "../axios"
import { useAuthContext } from "../hooks/useAuthContext"
import logo from "../images/logo192.png"
import Notfound from "./Notfound"


export default function Verify() {
    const {token} = useParams()
    const [success, setSuccess] = useState(false)
    const [message, setMessage] = useState('')
    const [isLoading,setIsLoading] = useState(false)
    const {dispatch} = useAuthContext()
   
   
  

    useEffect( ()=>{
       async function  go(){

        try{
          setIsLoading(true)
          await makeRequest.get(`/user/verify/${token}`)
        .then((res)=>{
             setSuccess(true)
             setIsLoading(false)
        })
        }catch(res){
            setIsLoading(true)
            setMessage(res.message)
            setIsLoading(false)
        }
       
       }
      go()

    },[token])
 
     
     if(success){
       return (
      
        <>
        {isLoading &&  <div className='fixed left-0 right-0 top-0 bottom-0 bg-themed4 opacity-75 flex items-center justify-center'>
                   
                         <img src={logo} className="w-16 h-16 animate-spin mb-2" /> 
                         <p className='text-white text-sm font-bold'>Signing you up, Please wait....</p>
                    
                  </div>}
        {!isLoading && (
           <div className="fixed left-0 right-0 top-0 bottom-0 bg-themed1 opacity-90 flex items-center justify-center">
           <div className="max-w-2xl bg-green-100 p-7 rounded-xl  flex flex-col items-center justify-center">
           
            <h2 className=" text-xl font-bold text-themed3">Verified</h2>
                        <p>You have successfully verified your email,
                          kindly login to your account
                        </p>
                   
                   
              <div className="flex flex-row mt-11 items-center justify-around w-full ">
              <Link to="/login"><button  className="px-3 py-2 text-sm font-bold bg-themed3 rounded-lg text-white">
                  GO TO LOGIN
               </button></Link>
                   
              </div>
                  
            </div>
        </div>
        )}
        
        
        </>
       )
      }else{
        return (!isLoading && <Notfound />)
      }
  
}
