
import logo from "../images/csimLogo.png"
export default function Footer() {
  return (
    <div className='w-full bg-themed4'>
       <div className='container mx-auto flex flex-col md:flex-row items-center justify-between py-5'>
       <img src={logo} alt="" className=" w-32 cursor-pointer"/>
      <p className="text-white">© 2022 Csimrital.in</p>
        
       </div>
    </div>
  )
}
