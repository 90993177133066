import {BrowserRouter, Routes, Route, Navigate} from "react-router-dom"
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import Allmatches from "./pages/Allmatches";
import Register from "./pages/Register";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Verify from "./pages/Verify";
import { useAuthContext } from "./hooks/useAuthContext";
import ForgotPassword from "./pages/ForgotPassword";
import Forgotpw from "./components/Forgotpw";
import Notfound from "./pages/Notfound";
import Profile from "./pages/Profile";
import Emailverify from "./components/Emailverify";
import Vemail from "./pages/Vemail";
import { useEffect, useState } from "react";
import { makeRequest } from "./axios";
import AddMatch from "./components/AddMatch";
import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query'
import MatchUserView from "./pages/MatchUserView";
import MatchSingle from "./pages/MatchSingle";
import Payment from "./pages/Payment";
import AddMatchp from "./pages/AddMatchp";
import Profileview from "./pages/Profileview";
import LikedMatch from "./pages/LikedMatch";
import { format } from 'date-fns'
import info from "./images/info.jpg"

function App() {
  const[crruser, setCrrUser]=useState()
  const {dispatch,currentUser,token,notification,enotification} = useAuthContext()
  const queryClient = new QueryClient()

  const today = new Date().toLocaleDateString('en-CA')

 const input = {
    token:token
  }

  useEffect(()=>{
    async function go(){
      await makeRequest.post("/users", input )

        .then((res)=>{
          dispatch({type:'ONCHANGE', payload:res.data.user })
          localStorage.setItem("user",JSON.stringify(res.data.user))
         
        })      
    }
    go()
  },[])
    
  return (
    <QueryClientProvider client={queryClient}>
    <div className="App">

        <BrowserRouter>
        {<Navbar />}
        {notification && 
              <div className="max-w-sm px-4 py-3 rounded-lg bg-green-200 text-green-800 text-sm z-200 absolute top-96 md:left-1/3 left-1/5">
                <img src={info} alt="info" className=" w-3 h-3" />
                {notification}
              </div>}
        
          {enotification && 
              <p className="max-w-sm px-4 py-3 rounded-lg bg-red-200 text-red-800 text-sm">{enotification}</p>
          }  
        <Routes>
           
           <Route path="/" 
                element ={currentUser  ?<Navigate to="/allmatches" />: <Home />}
            />
           <Route path="/allmatches"
            element={currentUser ? currentUser.expiry > today ? currentUser.matche ?<Allmatches/>:<Navigate to="/profile" />: <Navigate to="/rpayment" /> :<Navigate to="/"/>} 
            />
           <Route path="/register" 
           element={!currentUser ? <Register />: <Navigate to="/allmatches"/>}
            />
           <Route path="/login" 
            element={!currentUser  ? <Login />:<Navigate to="/" />} 
           />
          
           <Route path="/verify/user/:token" 
            element={ <Verify />}
          />
           <Route path="/fpassword" element={<ForgotPassword />}/>
           <Route path="/forgotpw/:token/:id" element={<Forgotpw />} />
           <Route path="/profile" 
           element={currentUser ? <Profile  currentUser={currentUser}/> : <Navigate to="/payment" />} 
           />
            <Route path="/matchuserview/:id" 
           element={currentUser ? <MatchUserView  currentUser={currentUser}/> : <Navigate to="/" />} 
           />
            <Route path="/matches/:id" 
           element={currentUser ? <MatchSingle /> : <Navigate to="/login" />} 
           />
            <Route path="/profileview/:id"
               element={currentUser ?<Profileview />:<Navigate to="/" />}
            />
             <Route path="/vemail" 
         element={!currentUser ?<Vemail />: <Navigate to="/allmatches" />} 
         />
          <Route path="/likedmatch/:id" element={currentUser ? <LikedMatch /> : <Navigate to="/" />} />
         <Route path="/addmatch"
           element={currentUser ? currentUser.razor_payment?<AddMatchp /> : <Navigate to="/profile" />: <Navigate to="/" />}
         />
          <Route path="/payment"
           element={currentUser ? currentUser.expiry > today ?<Allmatches />: <Payment /> : <Navigate to="/" />}
         />
         <Route path="/rpayment"
           element={currentUser ? currentUser.expiry > today ?<Allmatches />: <Payment /> : <Navigate to="/" />}
         />
         <Route path="*" element={<Notfound />} />
           
        </Routes>
        <Footer/>
        
        </BrowserRouter>
 
     
    </div>
    </QueryClientProvider>
  );
}

export default App;
