export const eduquals = [
    {
        id:"1",
        value:"Undergraduate"
    },
    { 
        id:"2",
        value:"Intermediate"
    },
    {
        id:"3",
        value:"Graduate"
    },
    {
        id:"4",
        value:"Post Graduate"
    },
    {
        id:"5",
        value:"Doctorate"
        
    },
    

]