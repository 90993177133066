
import React, { useState } from 'react'
import {Link, useNavigate} from 'react-router-dom';
import { useAuthContext } from '../hooks/useAuthContext';

import logo from "../images/logo192.png"
import dummy from "../images/dummy.webp"




export default function Register() {
  const [thumbnail,setThumbnail] = useState('')
  const [thumbnailError, setThumbnailError] = useState(false)
  const [inputError, setInputError] = useState('')
  const [filepath, setFilepath] = useState('')
  const [familyName, setFamilyName] = useState('')
  const [firstName, setfirstName] = useState('')
  const [lastName, setlastName] = useState('')
  const [email, setemail] = useState('')
  const [mobile, setmobile] = useState('')
  const [address, setaddress] = useState('')
  const [city, setcity] = useState('')
  const [pin, setPin] = useState('')
  const [dioceseName, setDioceseName] = useState('')
  const [churchName, setChurchName] = useState('')
  const [password, setPassword] = useState('')
  const [cnfpassword, setCnfPassword] = useState('')
  const [perror, setPerror] = useState('')

  const navigate = useNavigate()
  const [message,setMessage] =useState()

  const { signup, errors, isPending, finish} = useAuthContext()

 

  const handleFileChange = (e) =>{

    setThumbnail(null)
    let selected = e.target.files[0]
   if(selected){
          if(!selected.type.includes('image')){
            setThumbnailError('selected file must be an image')
            return
          }
          if(selected.size > 2000000){
            setThumbnailError('file size is more than allowed')
            return
          }

          setThumbnailError(null)
          setThumbnail(selected)
          setFilepath(URL.createObjectURL(selected))
   
    
      }
   }
  
  


 
  const handleSubmit =(e)=>{
    e.preventDefault()
   

    const inputs = {
      familyName:familyName,
      firstName:firstName,
      lastName:lastName,
      email:email,
      mobile:mobile,
      address:address,
      city:city,
      pin:pin,
      dioceseName:dioceseName,
      churchName:churchName,
      password:password,
      confirmation_password:cnfpassword,
      thumbnail:thumbnail
     
    }
         setInputError('')
        if(password == ''){
          setInputError('enter password')
          return
        }  
       if(cnfpassword == '' || password !== cnfpassword){
          setInputError("confirm password should match password")
          return
       }
      

        signup(inputs)
     
  }


  if(!finish){
   
    return (
      <div className=' bg-themel3 flex items-center text-center mt-10 -z-50 pt-10 pb-20'>
                
           <div className=' max-w-xl mx-auto flex-1 flex flex-col items bg-themel3 center p-3 overflow-auto'>
                <h1 className='text-4xl bg-themeblue text-themel2 font-bold py-4 px-6 mb-5'>Register</h1>
              <form onSubmit={handleSubmit}  className='flex flex-col space-y-2'>
                <button className='px-3 py-2 bg-themed3 text-themel3 relative h-20 cursor-pointer'>
                   <input type="file" 
                   placeholder='family name' 
                   className=' opacity-0 py-2 px-3 text-sm font-bold rounded bg-themel4 cursor-pointer' 
                   onChange={handleFileChange}
                 
                   />
                    <p className='w-full text-right md:text-center'>click above to select your photo</p>
                    {filepath && <img src={filepath} alt="" className='absolute w-16 h-16 top-1' />}
                    {!filepath && <img src={dummy} alt="" className='absolute w-16 h-16 top-1' />}
                </button>
                 {thumbnailError && <p className=' text-red-600'>{thumbnailError}</p>}
                 <input type="text"
                  placeholder='family name' 
                  className='py-2 px-3 text-sm font-bold rounded bg-themel4' 
                  onChange={(e)=>setFamilyName(e.target.value)}
                  value={familyName}
                  required={true}
                  />
                  {errors && <p className='text-xs text-red-600 font-bold'>{errors.familyName}</p>}
                  
                 <input type="text"
                  placeholder='first_name'
                  className='py-2 px-3 text-sm font-bold rounded bg-themel4'
                  onChange={(e)=>setfirstName(e.target.value)}
                  value={firstName}
                  required
                    />
                 {errors && <p className='text-xs text-red-600 font-bold'>{errors.firstName}</p>}
                 <input type="text"
                 placeholder='last name' 
                 className='py-2 px-3 text-sm font-bold rounded bg-themel4' 
                 onChange={(e)=>setlastName(e.target.value)}
                 value={lastName}
                 required
                   />
               {errors && <p className='text-xs text-red-600 font-bold'>{errors.lastName}</p>}
                 <input type="email"
                  placeholder='email' 
                  className='py-2 px-3 text-sm font-bold rounded bg-themel4' 
                  onChange={(e)=>setemail(e.target.value)}
                  value={email}
                  required
                  />
                   {errors && <p className='text-xs text-red-600 font-bold'>{errors.email}</p>}
                 <input type="text" 
                 placeholder='mobile' 
                 className='py-2 px-3 text-sm font-bold rounded bg-themel4' 
                 onChange={(e)=>setmobile(e.target.value)}
                 value={mobile}
                 required
                 />
                  {errors && <p className='text-xs text-red-600 font-bold'>{errors.mobile}</p>}
                 <input type="text" 
                 placeholder='address' 
                 className='py-2 px-3 text-sm font-bold rounded bg-themel4' 
                 onChange={(e)=>setaddress(e.target.value)}
                 value={address}
                 required
                 />
                 {errors && <p className='text-xs text-red-600 font-bold'>{errors.address}</p>}
                 <input type="text" 
                 placeholder='city' 
                 className='py-2 px-3 text-sm font-bold rounded bg-themel4' 
                 onChange={(e)=>setcity(e.target.value)}
                 value={city}
                 required
                 />
                 {errors && <p className='text-xs text-red-600 font-bold'>{errors.city}</p>}
                 <input type="text" 
                 placeholder='pin' 
                 className='py-2 px-3 text-sm font-bold rounded bg-themel4' 
                 onChange={(e)=>setPin(e.target.value)}
                 value={pin}
                 required
                 />
                 {errors && <p className='text-xs text-red-600 font-bold'>{errors.pin}</p>}
                  <input type="text" 
                 placeholder='diocese name' 
                 className='py-2 px-3 text-sm font-bold rounded bg-themel4' 
                 onChange={(e)=>setDioceseName(e.target.value)}
                 value={dioceseName}
                 required
                 />
                  {errors && <p className='text-xs text-red-600 font-bold'>{errors.dioceseName}</p>}
                  <input type="text" 
                 placeholder='church name' 
                 className='py-2 px-3 text-sm font-bold rounded bg-themel4' 
                 onChange={(e)=>setChurchName(e.target.value)}
                 value={churchName}
                 required
                 />
                 {errors && <p className='text-xs text-red-600 font-bold'>{errors.churchName}</p>}
                 <input type="password" 
                 placeholder='password' 
                 className='py-2 px-3 text-sm font-bold rounded bg-themel4' 
                 onChange={(e)=>setPassword(e.target.value)}
                 required
                 />
                 {errors && <p className='text-xs text-red-600 font-bold'>{errors.password}</p>}
                  <input type="password" 
                 placeholder='confirm password' 
                 className='py-2 px-3 text-sm font-bold rounded bg-themel4' 
                 onChange={(e)=>setCnfPassword(e.target.value)}
                 required
                 />
                  {inputError && <p className='text-red-600 text-sm font-bold'>{inputError}</p>}
                 {errors && <p className='text-xs text-red-600 font-bold'>{errors.confirmation_password}</p>}
                 { perror && <p className='text-sm text-red-500'>{perror}</p>}
                 {!isPending && <button onClick={handleSubmit} type="submit" className='py-3 px-4 bg-themed3 text-themel4 rounded-lg'>Submit</button>}
                 {isPending && <div className='fixed left-0 right-0 top-0 bottom-0 bg-themed4 opacity-75 flex items-center justify-center'>
                   
                         <img src={logo} className="w-16 h-16 animate-spin mb-2" /> 
                         <p className='text-white text-sm font-bold'>Signing you up, Please wait....</p>
                   
                  </div>}
                
              
              </form>

             
             
        </div>

      </div>

   )
  }else{
    return (
      <>
      <div className="fixed left-0 right-0 top-0 bottom-0 bg-themed1 opacity-90 flex items-center justify-center">
         <div className="max-w-2xl bg-green-100 p-7 rounded-xl  flex flex-col items-center justify-center">
         
          <h2 className=" text-xl font-bold text-themed3">Registration Successfull</h2>
                      <p>Your registration is successful, kindly verify your email by clicking
                        on the link sent to your email ID provided by you.
                         Thanks for using CSIMarital
                      </p>
                  
            <div className="flex flex-row mt-11 items-center justify-around w-full ">
            <Link to="/"><button  className="px-3 py-2 text-sm font-bold bg-themed3 rounded-lg text-white">
                 GO TO HOME
             </button></Link>
                 
            </div>
                
          </div>
      </div>
      
      </>
    )
  }

  
  
}
