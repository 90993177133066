import Header from "../components/Header";
import Register from "./Register";



export default function Home({user}) {
  return (
    
    <div>
    <Header />
      <div className="max-w-5xl mx-auto py-3 px-4 bg-themel3 rounded-lg -mt-9 ">
          <i className="text-right  text-sm text-themed4 leading-normal -mt-3">
                    Christian marriage is a solemn and public covenant between a man and a woman in the presence of God,
                    intended by God for their mutual joy; for the help and comfort given one another in prosperity and adversity;
                    and, when it is God's will, for the procreation of children and their nurture.                                                    
                </i>
                <p>-Episcopal Book of Common Prayer</p>
    </div>
    <div className='max-w-5xl mx-auto flex flex-col md:flex-row items-center justify-evenly mt-10  mb-14'>
        <div className='flex flex-col items-center md:border-r-2 border-themed4 px-4'>
          <h1 className='text-2xl text-themed4 font-bold'>Welcome</h1>
          <p>
                            Nonumy dolores dolor et duo dolor sit lorem amet.
                Clita rebum sit diam dolores vero clita. Vero ut 
                erat dolores sanctus at eos. Et sit diam elitr 
                dolore rebum nonumy, tempor clita dolor diam kasd 
                consetetur consetetur kasd et, ut ipsum labore vero 
                ut eos labore, ipsum ea lorem eos accusam ea ea voluptua 
                lorem invidunt, dolore invidunt no ea accusam. Est accusam 
                rebum et rebum nonumy amet sit duo,.
          </p>
        </div>
                          <div className='flex flex-col items-center  px-4 mt-5 md:mt-0' >
                          Nonumy dolores dolor et duo dolor sit lorem amet.
                Clita rebum sit diam dolores vero clita. Vero ut 
                erat dolores sanctus at eos. Et sit diam elitr 
                dolore rebum nonumy, tempor clita dolor diam kasd 
                consetetur consetetur kasd et, ut ipsum labore vero 
                ut eos labore, ipsum ea lorem eos accusam ea ea voluptua 
                lorem invidunt, dolore invidunt no ea accusam. Est accusam 
                rebum et rebum nonumy amet sit duo,.
        </div>

    </div>
  </div>
  )

}
