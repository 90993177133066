import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { makeRequest } from "../axios"
import Notfound from "../pages/Notfound"
import Resetpw from "./Resetpw"
import logo from "../images/logo192.png"
import { useAuthContext } from "../hooks/useAuthContext"
import { set } from "date-fns"



export default function Forgotpw() {
    const {token} = useParams()
    const [success,sestSuccess] = useState(false)
    const [isPending,setIsPending] = useState(false)
    const [error,setError] = useState()
    const [id,setId] = useState()
    const {setEnotification} = useAuthContext()
 
    useEffect(()=>{
         async function go(){
           
               setIsPending(true)
             await makeRequest.get(`/fgotpw/${token}`)
               .then((res)=>{
                    setId(res.data.user_id)
                    sestSuccess(true)
                    setIsPending(false)  
                    if(res.status == 401){
                       setEnotification('Something went wrong') 
                       setIsPending(false) 
                    } 
               })
               .catch(()=>{
                setEnotification('Something went wrong') 
                setIsPending(false)
               })
          
        }
        go()
    },[])






  return (
    <>
       {isPending && <div className='fixed left-0 right-0 top-0 bottom-0 bg-themed4 opacity-75 flex items-center justify-center'>
                   
                   <img src={logo} className="w-16 h-16 animate-spin mb-2" /> 
                   <p className='text-white text-sm font-bold'> Please wait....</p>
           
            </div>}

      {success && <Resetpw userid={id} />}
      {error && <Notfound />}
    </> 
  )
 
 }

