import { useState } from "react"
import { makeRequest } from "../axios"
import Razorpay from "../components/Razorpay"
import { useAuthContext } from "../hooks/useAuthContext"
import logo from "../images/logo192.png"


export default function Payment() {
         const [isPending, setIsPending] = useState(false)
         const [show, setShow] = useState(false)
         const [data,setData] = useState()
         const [error,setError] = useState('')
         const {currentUser, token} = useAuthContext()

       const input = {
        id:currentUser.id,
        token,
        amount:650
       }

     const handleSubmit = async (e)=>{
         e.preventDefault()
         setIsPending(true)
          await makeRequest.post("/createorder",input)
          .then((res)=>{
              setData(res.data.order)
              setShow(true)
              setIsPending(false)
          })
     }  

      const handleClose = ()=>{
        setShow(false)
        setIsPending(false)
      }
     
 
    return (
    <div className=' min-h-screen bg-themel3 flex items-center text-center -z-50 pt-10 pb-20'>
    <div className=' max-w-sm mx-auto flex-1 space-x-2 flex flex-col items bg-themed1 center p-3 overflow-auto rounded-xl '>
        <p className="text-xl text-white font-bold">Subscription Payment</p>
        <div className=" mt-4 p-3 rounded-md flex flex-col items-center space-y-3">
             <p className="text-sm font-bol text-white text-justify">
                In order to use the services of CSIMarital 
                and upload the details of your ward for seeking
                matches, you have to purchase a subscription
                 as detailed below.
             </p>
             <span className="py-3 px-4 bg-themel4 text-xl rounded-lg font-bold text-themed3">
                  <span className="text-xs font-normal text-themed1">Rs:</span>  650
             </span>
             <p className="text-sm font-bold text-themel4 text-justify">
                 On purchasing this subscription your published details will appear 
                 on this site for a period of three months.
             </p>
             {!isPending && <button onClick={handleSubmit} className="px-3 bg-themeblue rounded-lg py-2 text-sm font-bold text-themel4">Select and Pay</button>}
             {isPending &&  <div className='fixed left-0 right-0 top-0 bottom-0 bg-themed4 opacity-75 flex items-center justify-center'>
   
   <img src={logo} className="w-16 h-16 animate-spin mb-2" /> 
   <p className='text-white text-sm font-bold'>Please wait....</p>

</div> }
        </div>
    </div>
       {show && <Razorpay data={data} handleClose={handleClose} />}
   </div>
  )
}
