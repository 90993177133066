

export default function Vemail() {
  return (
    <div className="fixed top-0 right-0 bottom-0 left-0 bg-themed2  flex justify-center text-center items-center ">
    <div className=" max-w-lg mx-auto bg-white p-5 mt-3 md:mt-10 opacity-100 rounded-lg shadow-xl text-sm font-bold text-themed3"><i>
           Thank you for registering for CSIMarital, kindly verify your email by clicking the link
           sent by us to email ID provided by you while registering.
           </i>
           <span>- Team CSIMarital</span>
    </div>
    </div>
  )
}
