import { useState } from "react"
import { useMatch } from "../hooks/useMatch"
import logo from "../images/logo192.png"


export default function SendMessage({handleClose, user, id, token}) {
    
        const {isPending, errors, sendmessage, success} = useMatch()

    const inputs = {
         sendid:user.id,
         matchid:id,
         token:token
    }
 
      const handleSubmit = (e)=>{
        e.preventDefault()
         sendmessage(inputs)
      }

 if(!success){
    return (
    <>
     <div className="fixed left-0 right-0 top-0 bottom-0 bg-themed1 opacity-90 flex items-center justify-center">
     <div className="max-w-2xl bg-white p-7 rounded-xl  flex flex-col items-center justify-center">
         <div className="w-full p-3  text-right">
            <p onClick={handleClose} className="text-xs font-bold cursor-pointer">close</p>
         </div>
     <p>Are you sure to send message and give your consent to be contacted </p>
           <div className="flex flex-row mt-11 items-center justify-around w-full ">
           
               <button onClick={handleClose} className="px-3 py-2 text-sm font-bold bg-red-600 rounded-lg text-white">
                 Cancel
               </button>
               {!isPending && <button onClick={handleSubmit} className="px-5 py-2 text-sm font-bold bg-themed3 rounded-lg text-themel3">
                  Yes
               </button>}

               {isPending && <div className='fixed left-0 right-0 top-0 bottom-0 bg-themed4 opacity-75 flex items-center justify-center'>
                   
                         <img src={logo} className="w-16 h-16 animate-spin mb-2" /> 
                         <p className='text-white text-sm font-bold'>Sending your message, Please wait....</p>
                   
                  </div>}
           </div>
               
     </div>
     </div>
     
     </>
  )}else{
    return (
        <>
        <div className="fixed left-0 right-0 top-0 bottom-0 bg-themed1 opacity-90 flex items-center justify-center">
        <div className="max-w-2xl bg-green-300 p-7 rounded-xl  flex flex-col items-center justify-center">
            <div className="w-full p-3  text-right">
               <p onClick={handleClose} className="text-xs font-bold cursor-pointer">close</p>
            </div>
        <p className="text-lg text-green-800 font-bold">
            You have successfully sent message 
        </p>
              <div className="flex flex-row mt-11 items-center justify-around w-full ">
              <button onClick={handleClose} className="px-3 py-2 text-sm font-bold bg-red-600 rounded-lg text-white">
             CLOSE
               </button>
                   
              </div>
                  
        </div>
        </div>
        
        </>
    )
  }
}
