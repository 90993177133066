
import React, { useState } from 'react'
import {Navigate, useNavigate} from 'react-router-dom';
import { useAuthContext } from '../hooks/useAuthContext';
import { Photourl } from "../Photourl"
import logo from "../images/logo192.png"
import dummy from "../images/dummy.webp"



export default function Profilemodal({handleClose, setShow, udetails, token}) {
    const { photourl } = Photourl()
    const [thumbnail,setThumbnail] = useState()
    const [thumbnailError, setThumbnailError] = useState(false)
    const [inputError, setInputError] = useState('')
    const [filepath, setFilepath] = useState(`${photourl}/avatars/${udetails.profile_pic}`)
    const [familyName, setFamilyName] = useState(udetails.family_name)
    const [firstName, setfirstName] = useState(udetails.first_name)
    const [lastName, setlastName] = useState(udetails.last_name)
    const [email, setemail] = useState(udetails.email)
    const [mobile, setmobile] = useState(udetails.mobile)
    const [address, setaddress] = useState(udetails.address)
    const [city, setcity] = useState(udetails.city)
    const [pin, setPin] = useState(udetails.pin)
    const [dioceseName, setDioceseName] = useState(udetails.diocese_name)
    const [churchName, setChurchName] = useState(udetails.church_name)
    const [perror, setPerror] = useState('')
  
    const navigate = useNavigate()
  
   const { isPending, errors, updateprofile, success} = useAuthContext()
 
  
    const handleFileChange = (e) =>{
       let selected = e.target.files[0]
      if(selected)  {

        setThumbnail(null)
        let selected = e.target.files[0]
       
        if(!selected.type.includes('image')){
          setThumbnailError('selected file must be an image')
          return
        }
        if(selected.size > 1000000){
          setThumbnailError('file size is more than allowed')
          return
        }
    
        setThumbnailError(null)
        setThumbnail(selected)
        setFilepath(URL.createObjectURL(selected))

      }
      
    }
  
    const refresh = ()=>{
      window.location.reload(false)
    }
     
   
    const handleSubmit =(e)=>{
      e.preventDefault()
     
      
  
      const inputs = {
        familyName:familyName,
        firstName:firstName,
        lastName:lastName,
        email:email,
        mobile:mobile,
        address:address,
        city:city,
        pin:pin,
        dioceseName:dioceseName,
        churchName:churchName,
        thumbnail:thumbnail,
        id:udetails.id,
        token
       
      }    
      updateprofile(inputs)
     
    }

    if(success){
      refresh()
    }

  

  return (
    <div className="fixed top-0 right-0 bottom-0 left-0 bg-themel2  flex flex-col items-center overflow-scroll z-10">
    <div className=" bg-white p-5 mt-3 md:mt-10 opacity-100 rounded-lg shadow-xl z-50">
                <div className="w-full text-end">
                <button onClick={handleClose}  className="text-sm font-bold text-black ">close</button>
                </div>
         <h1 className='text-2xl bg-themeblue text-themel2 font-bold py-4 px-6 mb-5'>Update your Profile</h1>
              {success && <p className='text-sm text-green-600 font-bold'>The profile has been updated successfully</p>}
              
       <form onSubmit={handleSubmit}  className='flex flex-col space-y-2'>
         <button className='px-3 py-2 bg-themed3 text-themel3 relative h-20 cursor-pointer'>
            <input type="file" 
            placeholder='family name' 
            className=' opacity-0 py-2 px-3 text-sm font-bold rounded bg-themel4 cursor-pointer' 
            onChange={handleFileChange}
          
            />
             <p className=''>click above to select your photo</p>
             {filepath && <img src={filepath} alt="" className='absolute w-16 h-16 top-1' />}
             {!filepath && <img src={dummy} alt="" className='absolute w-16 h-16 top-1' />}
         </button>
          {thumbnailError && <p className=' text-red-600'>{thumbnailError}</p>}
          <input type="text"
           placeholder='family name' 
           className='py-2 px-3 text-sm font-bold rounded bg-themel4' 
           onChange={(e)=>setFamilyName(e.target.value)}
           value={familyName}
           required={true}
           />
             {errors && <p className='text-xs font-bold text-red-600'>{errors.familyName}</p>}
          <input type="text"
           placeholder='first_name'
           className='py-2 px-3 text-sm font-bold rounded bg-themel4'
           onChange={(e)=>setfirstName(e.target.value)}
           value={firstName}
           required
             />
             {errors && <p className='text-xs font-bold text-red-600'>{errors.firstName}</p>} 
          <input type="text"
          placeholder='last name' 
          className='py-2 px-3 text-sm font-bold rounded bg-themel4' 
          onChange={(e)=>setlastName(e.target.value)}
          value={lastName}
          required
            />
             {errors && <p className='text-xs font-bold text-red-600'>{errors.lastName}</p>}
          <input type="email"
           placeholder='email' 
           className='py-2 px-3 text-sm font-bold rounded bg-themel4' 
           onChange={(e)=>setemail(e.target.value)}
           value={email}
           required
           />
           {errors && <p className='text-xs font-bold text-red-600'>{errors.email}</p>}
          <input type="text" 
          placeholder='mobile' 
          className='py-2 px-3 text-sm font-bold rounded bg-themel4' 
          onChange={(e)=>setmobile(e.target.value)}
          value={mobile}
          required
          />
           {errors && <p className='text-xs font-bold text-red-600'>{errors.mobile}</p>}
          <input type="text" 
          placeholder='address' 
          className='py-2 px-3 text-sm font-bold rounded bg-themel4' 
          onChange={(e)=>setaddress(e.target.value)}
          value={address}
          required
          />
           {errors && <p className='text-xs font-bold text-red-600'>{errors.address}</p>}
          <input type="text" 
          placeholder='city' 
          className='py-2 px-3 text-sm font-bold rounded bg-themel4' 
          onChange={(e)=>setcity(e.target.value)}
          value={city}
          required
          />
           {errors && <p className='text-xs font-bold text-red-600'>{errors.city}</p>}
          <input type="text" 
          placeholder='pin' 
          className='py-2 px-3 text-sm font-bold rounded bg-themel4' 
          onChange={(e)=>setPin(e.target.value)}
          value={pin}
          required
          />
           {errors && <p className='text-xs font-bold text-red-600'>{errors.pin}</p>}
           <input type="text" 
          placeholder='diocese name' 
          className='py-2 px-3 text-sm font-bold rounded bg-themel4' 
          onChange={(e)=>setDioceseName(e.target.value)}
          value={dioceseName}
          required
          />
           {errors && <p className='text-xs font-bold text-red-600'>{errors.dioceseName}</p>}
           <input type="text" 
          placeholder='church name' 
          className='py-2 px-3 text-sm font-bold rounded bg-themel4' 
          onChange={(e)=>setChurchName(e.target.value)}
          value={churchName}
          required
          />
          {errors && <p className='text-xs font-bold text-red-600'>{errors.churchName}</p>}
        
          { perror && <p className='text-sm text-red-500'>{perror}</p>}
          {!isPending && <button onClick={handleSubmit} type="submit" className='py-3 px-4 bg-themed3 text-themel4 rounded-lg'>Submit</button>}
          {isPending && <div className='fixed left-0 right-0 top-0 bottom-0 bg-themed4 opacity-75 flex items-center justify-center'>
                   
                         <img src={logo} className="w-16 h-16 animate-spin mb-2" /> 
                         <p className='text-white text-sm font-bold'>Updating your profile, Please wait....</p>
                
                  </div>}
          {inputError && <p className='text-red-600 text-sm font-bold'>{inputError}</p>}
       
       </form>

      
      
 </div>

</div>
  )

 
}
