export const designations = [
    {
        id:"1",
        value:"Teacher",
    },
    {
        id:"2",
        value:"Lecturer/Professor",
    },
    {
        id:"3",
        value:"Medical Doctor",
    },
    {
        id:"4",
        value:"Lawyer",
    },
    {
        id:"5",
        value:"Civil Engineer",
    },
    {
        id:"6",
        value:"Software Engineer",
    },
    {
        id:"7",
        value:"Mechanical Engineer",
    },
    {
        id:"8",
        value:"Admin Officer",
    },
    {
        id:"9",
        value:"Marketing Official",
    },
    {
        id:"10",
        value:"other",
    },
    
]