import axios from "axios"
import { set } from "date-fns/esm"
import { useState } from "react"
import { makeRequest } from "../axios"
import { useAuthContext } from "../hooks/useAuthContext"
import logo from "../images/logo192.png"


export default function ForgotPassword({userid}) {
    const {resetpw, errors, isPending, success} = useAuthContext()
    const [input,setInput] = useState({
       password:"",
       confirmPassword:"",
       id:userid 
    })

    const [formError,setFormError] = useState({
        password:"",
        confirmPassword:""
    })


  
    

    const handleUserInput = (name,value)=>{
       setInput({
         ...input,
         [name]: value
       })
    }


    
    const handleSubmit =  async (e)=>{
        e.preventDefault()
        
        let inputError = {
            password:"",
            confirmPassword:""
        }

        if(!input.password ){
            setFormError({
            
                password:"please enter password",
               
            })
            return
        }

        if(!input.confirmPassword){
            setFormError({
            
                password:"please re-enter password",
               
            })
            return
        }

        if(input.password !== input.confirmPassword){
            setFormError({
                ...inputError,
                confirmPassword:"confirm password must match password"
            })
            return
        }

        resetpw(input)
    
        
    }
 
  return (
        <div className=' min-h-screen bg-themel3 flex items-center text-center -z-50 pt-10 pb-20'>
            <div className=' max-w-sm mx-auto flex-1 space-x-2 flex flex-col items bg-themed1 center p-3 overflow-auto rounded-xl '>
           
                <p className="text-2xl font-bold text-themel3 pb-8 ">Reset Password</p> 
                
                 <p className="text-lg text-red-400 font-bold">{errors}</p>
               
              {!success &&   <form onSubmit={handleSubmit}  className='flex flex-col space-y-4'>
                    <input type="password"
                        placeholder='enter your new password' 
                        name="password"
                        className='py-2 px-3 text-sm font-bold rounded bg-themel4' 
                        value={input.password}
                        onChange={({ target }) => {
                            handleUserInput(target.name, target.value);
                          }}
                        required
                        />
                       <p className="text-xs font-bold text-red-600">{formError.password}</p> 
                    <input type="password"
                        placeholder='re-enter the password' 
                        className='py-2 px-3 text-sm font-bold rounded bg-themel4' 
                        name="confirmPassword"
                        value={input.confirmPassword}
                        onChange={({ target }) => {
                            handleUserInput(target.name, target.value);
                          }}
                        required
                        />    
                     <p className="text-xs font-bold text-red-600">{formError.confirmPassword}</p> 
                  {!isPending && 
                  <button onClick={handleSubmit} type="submit" className='py-3 px-4   bg-themed3 text-themel4 rounded-lg'>Submit</button>
                  }
                  {isPending && <div className='fixed left-0 right-0 top-0 bottom-0 bg-themed4 opacity-75 flex items-center justify-center'>
                   
                   <img src={logo} className="w-16 h-16 animate-spin mb-2" /> 
                   <p className='text-white text-sm font-bold'> Please wait....</p>
           
            </div>}
                
                 </form>   }     
                     
            </div>
        </div>
  )
}
