import { useState } from "react"
import { Link, Navigate, useNavigate } from "react-router-dom"
import { makeRequest } from "../axios"
import { useAuthContext } from "../hooks/useAuthContext"
import { format } from 'date-fns'
import logo from "../images/logo192.png"



export default function Razorpay({handleClose, data}) {
             const [isPending,setIsPending] = useState(false)
             const [success,setSuccess] = useState(false)
             const [paymentDetails, setPaymentDetails] = useState()
             const {currentUser,  setNotification} = useAuthContext()
             const navigate = useNavigate()

      const handleSubmit = (e)=>{
        e.preventDefault()

        const script = document.createElement('script');
        script.src = 'https://checkout.razorpay.com/v1/checkout.js';
        script.onerror = () => {
          alert('Razorpay SDK failed to load. Are you online?');
        };
        script.onload = async () => {
          try {
            setIsPending(true);
          
            const options = {
              key: data.razorpayId,
              amount: data.amount.toString(),
              currency: data.currency,
              name: data.name,
              description: data.description,
              order_id: data.orderId,
              handler: async function (response) {
                const result = await makeRequest.post('/orderpayment', {
                  userid: currentUser.id,
                  amount: data.amount,
                  rzp_paymentid: response.razorpay_payment_id,
                  rzp_orderid: response.razorpay_order_id,
                  rzp_signature: response.razorpay_signature,
                });
               if(result.data.payment){
                  setPaymentDetails(result.data.payment)
                  setSuccess(true)
               }
              },
              prefill: {
              
                email: data.email,
                contact:data.contactNumber,
              },
              notes: {
                address: data.address,
              },
              theme: {
                color: '#1F2492',
              },
            };
           
            setIsPending(false);
            const paymentObject = new window.Razorpay(options);
            paymentObject.open();
          } catch (err) {
            alert(err);
            setIsPending(false);
          }
        };
        document.body.appendChild(script);
       
      }

     const handleClick = (e)=>{
      e.preventDefault()
       navigate("/")
     }


 if(!success){
    return (
    <>
     <div className="fixed left-0 right-0 top-0 bottom-0 bg-themed1 opacity-90 flex items-center justify-center">
     <div className=" w-5/6 md:w-1/2 bg-white p-7 rounded-xl  flex flex-col items-center justify-center">
         <div className="w-full   text-right">
            <p onClick={handleClose} className="text-xs font-bold cursor-pointer">close</p>
         </div>
              <h2 className=" text-xl font-bold text-themed3">Make Payment</h2>
                    
              <table className=" table-fixed">
                    <tr className="py-3">
                        <th className="px-4 text-left text-themed2">Subscripton</th>
                        <td className="text-xl text-themed2 font-light">GOLD</td>
                    </tr>
                    <tr className="py-3">
                        <th className="px-4 text-left text-themed2">Amount</th>
                        <td className="text-xl text-themed2 font-light">650</td>
                    </tr>
                    <tr className="py-3">
                        <th className="px-4 text-left text-themed2">Period of subscription</th>
                        <td className="text-xl text-themed2 font-light">3 months</td>
                    </tr>
               </table>         




           <div className="flex flex-row mt-11 items-center justify-around w-full ">
           
               <button onClick={handleClose} className="px-3 py-2 text-sm font-bold bg-red-600 rounded-lg text-white">
                 Cancel
               </button>
               {!isPending && <button onClick={handleSubmit} className="px-5 py-2 text-sm font-bold bg-themed3 rounded-lg text-themel3">
                  Pay
               </button>}

               {isPending && <div className='fixed left-0 right-0 top-0 bottom-0 bg-themed4 opacity-75 flex items-center justify-center'>
                   
                   <img src={logo} className="w-16 h-16 animate-spin mb-2" /> 
                   <p className='text-white text-sm font-bold'>Uploading your match, Please wait....</p>
           
            </div>}
           </div>
               
     </div>
     </div>
     
     </>
  )}else{
    return (
      <Navigate to="/addmatch" />
    )
  }
}
