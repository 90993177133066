import { useState } from "react"
import { makeRequest } from "../axios"



export const useMatch = ()=>{
    const [isPending, setIsPending] = useState()
    const [errors,setErrors] = useState()
    const [success,setSuccess] = useState(false)

    const addmatch = async (inputs)=>{
        try{
            setIsPending(true)
            await makeRequest.post("/addmatch", inputs,{
                headers:{
                    "Content-Type": "multipart/form-data",
                }
            })
           
            .then((res)=>{
              setSuccess(true)
            })
            setIsPending(false)
           }catch(errors){
            setIsPending(true)
            setErrors(errors.response.data.errors)
            setIsPending(false)
           }
        }


        const editmatch = async (inputs)=>{
            try{
                setIsPending(true)
                await makeRequest.post("/editmatch", inputs,{
                    headers:{
                        "Content-Type": "multipart/form-data",
                    }
                })
               
                .then((res)=>{
                   setSuccess(true)        
                })
                setIsPending(false)
               }catch(errors){
                setIsPending(true)
                setErrors(errors.response.data.errors)
                setIsPending(false)
               }
            }

            const sendmessage =  async (inputs)=>{
                try{
                    setIsPending(true)
                    await makeRequest.post("/sendmessage", inputs)
                    .then((res)=>{
                      setSuccess(true)
                    })
                    setIsPending(false)
                }catch(errors){
                     setIsPending(true)
                     setErrors(errors.data.response.errors)
                     setIsPending(false)
                }
            }



    return { addmatch,editmatch, isPending, errors, success, sendmessage}
}