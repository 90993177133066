import { useParams } from "react-router-dom"
import { useAuthContext } from "../hooks/useAuthContext"
import { useQuery } from "@tanstack/react-query";
import { makeRequest } from "../axios";
import { Photourl } from "../Photourl";
import EditUserMatch from "../components/EditUserMatch";
import { useEffect, useState } from "react";
import Tippy from '@tippy.js/react'
import 'tippy.js/dist/tippy.css'
import Tolltip from "../components/Tolltip";
import SendMessage from "../components/SendMessage";
import dummy from "../images/dummy.webp"
import logo from "../images/logo512.webp"



export default function MatchSingle() {
    const {id} = useParams()
    const {token, currentUser} = useAuthContext()
    const [match, setMatch] = useState()
    const [isLoading,setIsLoading] = useState(false)
    const [data,setData] = useState({})
    const [show,setShow] = useState(false)
    const message = "You can like and send message to the prospect's parent/guardian. you will be giving your contact details for them to contact you and a link to view your prospect's details"
    const [liked,setLiked] = useState(false)
    const {photourl} = Photourl()


    const input = {
        id:id,
        token:token
    }

   {/*
      const { isLoading, error, data } = useQuery(['matches'], ()=>{
        const res = makeRequest.post("/matchuser",input)
            
             return res.data.match
       
      })
    
    */}
   
      useEffect(()=>{
        async function go(){
          setIsLoading(true)
         await  makeRequest.post("/matchsingle",input)
         .then((res)=>{
            setData(res.data.matches)
            setLiked(res.data.liked)
         })
          setIsLoading(false)  
        }
        go()
      },[])

        const handleClick = (e)=>{
           e.preventDefault()
           setShow(true)
        }

        const handleClose = ()=>{
            window.location.reload()
        }
    
  return (
    <>
         {!isLoading && (
          <div className=" min-h-screen max-w-5xl mx-auto  flex flex-col md:flex-row items-center justify-around mt-10 ">
           
             <div className="max-w-2xl mx-auto flex-1 md:w-96 mt-10 p-5  md:mr-7 "   >
                  
             <div className="w-full">
                
                <div key={data.id}>
                  {data.bust_image && <img  src={`${photourl}/bust/${data.bust_image}`} alt="" className="py-3" />}
                  {!data.bust_image &&<img  src={dummy} alt="" className="py-3" />}
                 
                </div>
               
               
                <div key={data.id}>
                 
                  {data.full_image && <img  src={`${photourl}/full/${data.full_image}`} alt="" className="py-3" />}
                  {!data.full_image && <img  src={dummy} alt="" className="py-3" />}
                 
                </div>
              
              
                <div key={data.id}>
                  {data.family_image && <img src={`${photourl}/family/${data.family_image}`} alt="" className="py-3" />}
                  {!data.family_image && <img src={dummy} alt="" className="py-3" />}
                </div>
              
              
               
              </div>
             
         </div>

      
         
          <div className="flex-1  min-h-screen p-5 md:p-10 ">
                
                    <div className=" flex flex-col justify-datas-start space-y-3">
                   
                      <h1 className="text-2xl font-bold text-themed2 mb-8 ">{data.first_name}  {data.last_name}  {data.family_name}</h1>
                  

                     <table className=" table-fixed">
                        <tr className="py-3">
                            <th className="px-4 text-left text-themed2">Father</th>
                            <td className="text-xl text-themed2 font-light">{data.fname}</td>
                        </tr>
                        <tr className="py-3">
                            <th className="px-4 text-left text-themed2">Mother</th>
                            <td className="text-xl text-themed2 font-light">{data.mname}</td>
                        </tr>
                        <tr className="py-3">
                            <th className="px-4 text-left text-themed2">Date of Birth</th>
                            <td className="text-xl text-themed2 font-light">{data.date_of_birth}</td>
                        </tr>
                        <tr className="py-3">
                            <th className="px-4 text-left text-themed2">Qualifications</th>
                            <td className="text-xl text-themed2 font-light">{data.eduqual}</td>
                        </tr>
                        <tr className="py-3">
                            <th className="px-4 text-left text-themed2">Working as </th>
                            <td className="text-xl text-themed2 font-light">{data.cadre}</td>
                        </tr>
                        <tr className="py-3">
                            <th className="px-4 text-left text-themed2">Nature of job</th>
                            <td className="text-xl text-themed2 font-light">{data.desgn}</td>
                        </tr>
                        <tr className="py-3">
                            <th className="px-4 text-left text-themed2">Organization Type</th>
                            <td className="text-xl text-themed2 font-light">{data.occu}</td>
                        </tr>
                        <tr className="py-3">
                            <th className="px-4 text-left text-themed2">Place of Work</th>
                            <td className="text-xl text-themed2 font-light">{data.place_of_work}</td>
                        </tr>
                        <tr className="py-3">
                            <th className="px-4 text-left text-themed2">Father's Occupation</th>
                            <td className="text-xl text-themed2 font-light">{data.foccu}</td>
                        </tr>
                        <tr className="py-3">
                            <th className="px-4 text-left text-themed2">Mother's Occupation</th>
                            <td className="text-xl text-themed2 font-light">{data.moccu}</td>
                        </tr>
                       
                     </table>
                     <div >
                         <span className="text-lg text-themed3 font-bold">About {data.first_name}</span>
                         <p className="text-sm font-medium text-themed2">{data.about}</p>
                     </div>
                     <div >
                         <span className="text-lg text-themed3 font-bold">About Family</span>
                         <p className="text-sm font-medium text-themed2">{data.about_family}</p>
                     </div>
                      
                    </div>   
               

                 <div className="w-full text-center pt-3">
                    
                 {!liked && 
                 <Tippy placement="right" content={<Tolltip message={message} />} >
                 <button onClick={handleClick} className="px-3 py-2 bg-themed3 text-themel4  text-sm font-bold rounded-md">SEND LIKE</button>
                 </Tippy>} 
                 {liked && <p className="px-3 py-2 bg-green-200 text-green-800 text-sm font-bold rounded-md">You already sent message</p>}
                 </div>
              </div>
             {show &&  <SendMessage handleClose={handleClose} user={currentUser} id={id} token={token} />}
        </div>
        )}
      {isLoading && (
             <div className='fixed left-0 right-0 top-0 bottom-0 bg-themed4 opacity-75 flex items-center justify-center'>
                   
             <img src={logo} className="w-16 h-16 animate-spin mb-2" /> 
             <p className='text-white text-sm font-bold'>fetching details, Please wait....</p>
     
          </div>
      )}
    </>    
  )
}
