import { Link, useParams } from "react-router-dom"
import { useAuthContext } from "../hooks/useAuthContext"
import { useQuery } from "@tanstack/react-query";
import { makeRequest } from "../axios";
import { Photourl } from "../Photourl";
import EditUserMatch from "../components/EditUserMatch";
import { useEffect, useState } from "react";
import logo from "../images/logo512.webp"





export default function MatchUserView() {
    const {id} = useParams()
    const {token} = useAuthContext()
    const [showModal, setShowModal] = useState(false)
    const [match, setMatch] = useState()
    const [data,setData] = useState({})
    const [isLoading,setIsLoading] = useState(false)
    const [likedby, setLikedby] = useState()
    const {photourl} =Photourl()
   
    const handleClose =()=>{
        setShowModal(false)
    }
    const handleClick = ()=>{
        setShowModal(true)
    }

    const input = {
        id:id,
        token:token
    }

   {/*
      const { isLoading, error, data } = useQuery(['matches'], ()=>{
        const res = makeRequest.post("/matchuser",input)
            
             return res.data.match
       
      })
    
    */}
   
      useEffect(()=>{
        async function go(){
          setIsLoading(true)
          await  makeRequest.get(`/matchuser/${id}`)
         .then((res)=>{
           setData(res.data.match)
           setLikedby(res.data.likedby)
         })
            setIsLoading(false)
        }
        go()
      },[])

    

  return (
     <>
     {!isLoading && (
    <div className=" min-h-screen max-w-5xl mx-auto  flex flex-col md:flex-row items-center justify-around mt-10 ">
           
             <div className="max-w-2xl mx-auto flex-1 md:w-96 mt-10 p-5  md:mr-7 "   >
                  
             <div className="w-full">
                
                <div key={data.id}>
                  <img  src={`${photourl}/bust/${data.bust_image}`} alt="" className="py-3" />
                 
                </div>
               
               
                <div key={data.id}>
                 
                  <img  src={`${photourl}/full/${data.full_image}`} alt="" className="py-3" />
                 
                </div>
              
              
                <div key={data.id}>
                  <img src={`${photourl}/family/${data.family_image}`} alt="" className="py-3" />
                </div>
              
              
               
              </div>
             
         </div>

      
         
          <div className="flex-1  min-h-screen p-5 md:p-10 ">
                
                    <div className=" flex flex-col justify-datas-start space-y-3">
                   
                      <h1 className="text-2xl font-bold text-themed2 mb-8 ">{data.first_name}  {data.last_name}  {data.family_name}</h1>
                  

                     <table className=" table-fixed">
                        <tr className="py-3">
                            <th className="px-4 text-left text-themed2">Father</th>
                            <td className="text-xl text-themed2 font-light">{data.fname}</td>
                        </tr>
                        <tr className="py-3">
                            <th className="px-4 text-left text-themed2">Mother</th>
                            <td className="text-xl text-themed2 font-light">{data.mname}</td>
                        </tr>
                        <tr className="py-3">
                            <th className="px-4 text-left text-themed2">Date of Birth</th>
                            <td className="text-xl text-themed2 font-light">{data.date_of_birth}</td>
                        </tr>
                        <tr className="py-3">
                            <th className="px-4 text-left text-themed2">Qualifications</th>
                            <td className="text-xl text-themed2 font-light">{data.eduqual}</td>
                        </tr>
                        <tr className="py-3">
                            <th className="px-4 text-left text-themed2">Working as </th>
                            <td className="text-xl text-themed2 font-light">{data.cadre}</td>
                        </tr>
                        <tr className="py-3">
                            <th className="px-4 text-left text-themed2">Nature of job</th>
                            <td className="text-xl text-themed2 font-light">{data.desgn}</td>
                        </tr>
                        <tr className="py-3">
                            <th className="px-4 text-left text-themed2">Organization Type</th>
                            <td className="text-xl text-themed2 font-light">{data.occu}</td>
                        </tr>
                        <tr className="py-3">
                            <th className="px-4 text-left text-themed2">Place of Work</th>
                            <td className="text-xl text-themed2 font-light">{data.place_of_work}</td>
                        </tr>
                        <tr className="py-3">
                            <th className="px-4 text-left text-themed2">Father's Occupation</th>
                            <td className="text-xl text-themed2 font-light">{data.foccu}</td>
                        </tr>
                        <tr className="py-3">
                            <th className="px-4 text-left text-themed2">Mother's Occupation</th>
                            <td className="text-xl text-themed2 font-light">{data.moccu}</td>
                        </tr>
                       
                     </table>
                     <div >
                         <span className="text-lg text-themed3 font-bold">About {data.first_name}</span>
                         <p className="text-sm font-medium text-themed2">{data.about}</p>
                     </div>
                     <div >
                         <span className="text-lg text-themed3 font-bold">About Family</span>
                         <p className="text-sm font-medium text-themed2">{data.about_family}</p>
                     </div>
                      
                    </div>   
               

                 <div className="w-full text-center pt-3">
                 <button onClick={handleClick} className="px-3 py-2 bg-themed3 text-themel3 rounded-md">EDIT</button>
                 </div>
                 {likedby && (
                      <div className=" mt-7 w-full flex flex-col items-center space-x-5  justify-between">
                             <h2 className="mb-6">Liked by </h2>

                          {likedby.map((item,index)=>(
                            <>
                     
                  
                             <Link to={`/profileview/${item.sentby.id}`}><div className=" w-52 px-6 py-2 rounded-lg flex items-center justify-evenly space-x-5 mb-5 bg-themel2">
                             <img src={`${photourl}/avatars/${item.sentby.profile_pic}`} className=" w-7 h-7 rounded-full"/>
                                   <p>{item.sentby.first_name}</p>
                                   <p>{item.sentby.last_name}</p>
                             </div></Link>
                            
                           </>
                          ))}   
                      </div>
                 )}
                

              </div>
             
              {showModal && (
       <EditUserMatch handleClose={handleClose} setShowModal={setShowModal}  match={data} token={token} />  
      )}
      </div>
      )}
      {isLoading && (
         <div className='fixed left-0 right-0 top-0 bottom-0 bg-themed4 opacity-75 flex items-center justify-center'>
                   
         <img src={logo} className="w-16 h-16 animate-spin mb-2" /> 
         <p className='text-white text-sm font-bold'>fetching details, Please wait....</p>
 
      </div>
      )}
     </>  
  )
}
