
import { useEffect, useState } from "react"
import { makeRequest } from "../axios"
import { useAuthContext } from "../hooks/useAuthContext"
import MatchList from "../components/MatchList"
import { Navigate } from "react-router-dom"
import { format } from "date-fns"
import logo from  "../images/logo512.webp"


export default function Allmatches() {
    const [matches,setMatches] = useState({})
    const {currentUser,token, setNotification} = useAuthContext()
    const [isLoading,setIsLoading] = useState(false)
  
    const input = {
      id:currentUser.id
    }
  
    useEffect(()=>{
      async function go(){
       setIsLoading(true)
        await makeRequest.get(`/allmatches/${currentUser.id}`)
        .then((res)=>{
          console.log(res)
          setMatches(res.data.matches)
        })
        setIsLoading(false)
      }
      go()
    },[])

  

 
  return (
    <>
    {!isLoading && (
       <div className=" mt-14 flex flex-row p-10 items-center justify-between  ">
      
       {matches && <MatchList matches = {matches} />}

   </div>
    )}
    {isLoading && (
        <div className='fixed left-0 right-0 top-0 bottom-0 bg-themed4 opacity-75 flex items-center justify-center'>
                   
        <img src={logo} className="w-16 h-16 animate-spin mb-2" /> 
        <p className='text-white text-sm font-bold'>fetching all matches, Please wait....</p>

     </div>
    )}
   
    </>
  )
 
}

