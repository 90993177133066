import {  useState } from "react"

import { eduquals } from "../Formaddons/Eduqual"
import { designations } from "../Formaddons/Designations"
import { occupations } from "../Formaddons/Occupations"
import { Cadres } from "../Formaddons/Cadres"
import { makeRequest } from "../axios"
import { useAuthContext } from "../hooks/useAuthContext"
import { useMatch } from "../hooks/useMatch"
import logo from "../images/logo192.png"


export default function AddMatchp() {
    
    const [matchtype,setMatchType] = useState()
    const [relation, setRelation]= useState('')
    const [familyName,setFamilyName] = useState('')
    const [firstName,setFirstName] = useState('')
    const [lastName,setLastName] = useState('')
    const [birthday,setBirthDay] = useState('')
    const [eduQalifications, setEducationQualifications] = useState('')
    const [occupation, setOccupation] = useState('')
    const [cadre,setCadre]= useState('')
    const [designation, setDesignation] = useState('')
    const [placeOfWork, setPlaceOfWork] = useState('')
    const [fname,setFname] = useState('')
    const [mname,setMname] = useState('')
    const [foccu,setFoccu] = useState('')
    const [moccu, setMoccu] = useState('')
    const [about,setAbout] = useState('')
    const [fabout, setFabout] = useState('')
    const [thumbnail1, setThumbnail1] = useState()
    const [thumbnail2, setThumbnail2] = useState()
    const [thumbnail3, setThumbnail3] = useState()
    const [thumbnailError1, setThumbnailError1]=useState(false)
    const [filepath1,setFilepath1]=useState('')
    const [thumbnailError2, setThumbnailError2]=useState(false)
    const [filepath2,setFilepath2]=useState('')
    const [thumbnailError3, setThumbnailError3]=useState(false)
    const [filepath3,setFilepath3]=useState('')
    const [error,setError] = useState('')
    const {currentUser, token } = useAuthContext()
   
    const {addmatch, isPending,errors, success } = useMatch()
    
  
 
   const handleFileChange1 = (e) =>{
       setThumbnail1(null)
       let selected = e.target.files[0]
       if(! e.target.files[0]){
         setThumbnailError1('please select your profile photo to upload')
         return
       }
       if(!selected){
         setThumbnailError1('please select a file to upload')
         return
       }
       if(!selected.type.includes('image')){
         setThumbnailError1('selected file must be an image')
         return
       }
       if(selected.size > 4000000){
         setThumbnailError1('file size is more than allowed')
         return
       }
   
       setThumbnailError1(null)
       setThumbnail1(selected)
       setFilepath1(URL.createObjectURL(selected))
       
     }
 
     const handleFileChange2 = (e) =>{
       setThumbnail2(null)
       let selected = e.target.files[0]
       if(! e.target.files[0]){
         setThumbnailError2('please select your profile photo to upload')
         return
       }
       if(!selected){
         setThumbnailError2('please select a file to upload')
         return
       }
       if(!selected.type.includes('image')){
         setThumbnailError2('selected file must be an image')
         return
       }
       if(selected.size > 4000000){
         setThumbnailError2('file size is more than allowed')
         return
       }
   
       setThumbnailError2(null)
       setThumbnail2(selected)
       setFilepath2(URL.createObjectURL(selected))
       
     }
 
     const handleFileChange3 = (e) =>{
       setThumbnail3(null)
       let selected = e.target.files[0]
       if(! e.target.files[0]){
         setThumbnailError3('please select your profile photo to upload')
         return
       }
       if(!selected){
         setThumbnailError3('please select a file to upload')
         return
       }
       if(!selected.type.includes('image')){
         setThumbnailError3('selected file must be an image')
         return
       }
       if(selected.size > 4000000){
         setThumbnailError3('file size is more than allowed')
         return
       }
   
       setThumbnailError3(null)
       setThumbnail3(selected)
       setFilepath3(URL.createObjectURL(selected))
       
     }


    const refresh = ()=>{
      window.location.reload(false)
    }
 
      const inputs = {
        userId:currentUser.id,
        matchType:matchtype,
        bustImage:thumbnail1 ,
        fullImage:thumbnail2,
        familyImage:thumbnail3,
        relation:relation,
        familyName:familyName,
        firstName:firstName,
        lastName:lastName,
        dateOfBirth: birthday,
        eduqualifications:eduQalifications,
        cadre:cadre,
        designation:designation,
        occupation:occupation,
        placeOfWork:placeOfWork,
        aboutProspect:about,
       fname:fname,
        mname:mname,
        foccu:foccu,
        moccu:moccu,
        aboutFamily:fabout,
        token
       
      }
  
     const handleSubmit = async (e)=>{
       e.preventDefault()
        addmatch(inputs) 
       
     }

     if(success){
        refresh()
     }
        
 
  return (
    <div className="fixed top-0 right-0 bottom-0 left-0 bg-themed2  flex flex-col items-center overflow-scroll">
    <div className=" bg-white p-5 mt-3 md:mt-10 opacity-100 rounded-lg shadow-xl ">
        
        <div className=' max-w-xl mx-auto flex-1 flex flex-col items white center p-3'>
                   <h1 className='text-4xl bg-themeblue text-themel2 font-bold py-4 px-6 mb-5'>Add Match</h1>
                 <form  onSubmit={handleSubmit} className='flex flex-col space-y-2'>
                   <button className='px-3 py-2 bg-themed3 text-themel3 relative h-20 cursor-pointer '>
                      
                      <input type="file" 
                      placeholder='profile photo' 
                      className='block opacity-0 py-2 px-3 text-sm font-medium rounded bg-themel4 cursor-pointer' 
                      onChange={handleFileChange1}
                      required
                      />
                        
                       <p className=' z-50 '>click above to select a bust photo</p>
                       {filepath1 && <img src={filepath1} alt="" className='absolute w-16 h-16 top-1' />}
                   </button>
                   {thumbnailError1 && <p className='text-sm font-bold text-red-600'>{thumbnailError2}</p>}
                  {errors && <p className=' text-sm font-bold text-red-600'>{errors.bustImage}</p>}
                   <button className='px-3 py-2 bg-themed3 text-themel3 relative h-20 cursor-pointer '>
                      
                      <input type="file" 
                      placeholder='profile photo' 
                      className='block opacity-0 py-2 px-3 text-sm font-medium rounded bg-themel4 cursor-pointer' 
                      onChange={handleFileChange2}
                      required
                      />
                        
                       <p className=' z-50 '>click above to select a full size photo</p>
                       {filepath2 && <img src={filepath2} alt="" className='absolute w-16 h-16 top-1' />}
                   </button>
                   {thumbnailError2 && <p className=' text-red-600'>{thumbnailError2}</p>}
                   {errors && <p className=' text-sm font-bold text-red-600'>{errors.fullImage}</p>}
                   <button className='px-3 py-2 bg-themed3 text-themel3 relative h-20 cursor-pointer '>
                      
                      <input type="file" 
                      placeholder='profile photo' 
                      className='block opacity-0 py-2 px-3 text-sm font-medium rounded bg-themel4 cursor-pointer' 
                      onChange={handleFileChange3}
                      required
                      />
                        
                       <p className=' z-50 '>click above to select a family photo</p>
                       {filepath3 && <img src={filepath3} alt="" className='absolute w-16 h-16 top-1' />}
                   </button>
                    {thumbnailError3 && <p className=' text-red-600'>{thumbnailError3}</p>}
                    {errors && <p className=' text-sm font-bold text-red-600'>{errors.familyImage}</p>}
                   
                   <select
                      placeholder="type"
                      className='py-2 px-3 text-sm font-medium rounded border-b-2 border-gray-300' 
                      onChange={(e)=>setMatchType(e.target.value)}
                      value={matchtype}
                      required
                    >
                       
                     <option value="" className="text-gray-400">--select type of match bride/groom--</option>
                     <option value="Bride">Bride</option>
                     <option value="Groom">Groom</option>
                    </select>
                    {errors && <p className="text-xs text-red-500 font-bold">{errors.matchType}</p>}
                    <select
                      placeholder="relation"
                      className='py-2 px-3 text-sm font-medium rounded border-b-2 border-gray-300' 
                      onChange={(e)=>setRelation(e.target.value)}
                      value={relation}
                      required
                    >
                        {errors && <p className="text-xs text-red-500 font-bold">{errors.relation}</p>}
                     <option value="" className="text-gray-400">--select your relation to the prospect--</option>
                     <option value="Father">Father</option>
                     <option value="Mother">Mother</option>
                     <option value="Uncle">Uncle</option>
                     <option value="Other">Other</option>
                    </select>
                    <input type="text"
                     placeholder='family name' 
                     className='py-2 px-3 text-sm font-medium rounded border-b-2 border-gray-300' 
                     onChange={(e)=>setFamilyName(e.target.value)} 
                     value={familyName}
                     required
                     />
                     {errors && <p className="text-xs text-red-500 font-bold">{errors.familyName}</p>}
                    <input type="text"
                     placeholder='first_name'
                     className='py-2 px-3 text-sm font-medium rounded border-b-2 border-gray-300'
                     onChange={(e)=>setFirstName(e.target.value)}
                     value={firstName}
                     required
                       />
                     {errors && <p className="text-xs text-red-500 font-bold">{errors.firstName}</p>}  
                    <input type="text"
                    placeholder='last name' 
                    className='py-2 px-3 text-sm font-medium rounded border-b-2 border-gray-300' 
                    onChange={(e)=>setLastName(e.target.value)}
                    value={lastName}
                    required
                      />   
                   {errors && <p className="text-xs text-red-500 font-bold">{errors.lastName}</p>}   
                    <span>Date of birth</span>  
                    <input 
                    placeholder="date of birth"
                    className='py-2 px-3 text-sm font-medium rounded border-b-2 border-gray-300' 
                    type="date"
                    onChange={(e)=>setBirthDay(e.target.value)}
                    value={birthday}
                    required
                    /> 
                    {errors && <p className="text-xs text-red-500 font-bold">{errors.dateOfBirth}</p>}

                    <select
                    className='py-2 px-3 text-sm font-medium rounded border-b-2 border-gray-300' 
                    onChange={(e)=>setEducationQualifications(e.target.value)}
                    value={eduQalifications}
                    required
                    >
                      <option value="" className="text-gray-300">--select eduQalifications --</option>
                      {eduquals.map((item)=>(
                         <option key={item.id} value={item.value} className="text-gray-500">{item.value}</option>
                      ))}
                    </select>
                    {errors && <p className="text-xs text-red-500 font-bold">{errors.eduqualifications}</p>}
                    <select
                    className='py-2 px-3 text-sm font-medium rounded border-b-2 border-gray-300' 
                    onChange={(e)=>setCadre(e.target.value)}
                    value={cadre}
                    required
                    >
                      <option value="" className="text-gray-300">--select cadre --</option>
                      {Cadres.map((item)=>(
                         <option key={item.id} value={item.value} className="text-gray-500">{item.value}</option>
                      ))}
                    </select>
                    {errors && <p className="text-xs text-red-500 font-bold">{errors.cadre}</p>}
                    <select
                    className='py-2 px-3 text-sm font-medium rounded border-b-2 border-gray-300' 
                    onChange={(e)=>setDesignation(e.target.value)}
                    value={designation}
                    required
                    >
                      <option value="" className="text-gray-300">--select designation --</option>
                      {designations.map((item)=>(
                         <option key={item.id} value={item.value} className="text-gray-500">{item.value}</option>
                      ))}
                    </select>
                    {errors && <p className="text-xs text-red-500 font-bold">{errors.designation}</p>}
                    
                    <select
                    className='py-2 px-3 text-sm font-medium rounded border-b-2 border-gray-300' 
                    onChange={(e)=>setOccupation(e.target.value)}
                    value={occupation}
                    required
                    >
                      <option value="" className="text-gray-300">--select occupation --</option>
                      {occupations.map((item)=>(
                         <option key={item.id} value={item.value} className="text-gray-500">{item.value}</option>
                      ))}
                    </select>
                    {errors && <p className="text-xs text-red-500 font-bold">{errors.occupation}</p>}
                    <input type="text"
                    placeholder='place of Work' 
                    className='py-2 px-3 text-sm font-medium rounded border-b-2 border-gray-300' 
                    onChange={(e)=>setPlaceOfWork(e.target.value)}
                    value={placeOfWork}
                    required
                      />   
                    {errors && <p className="text-xs text-red-500 font-bold">{errors.placeOfWork}</p>}
                    <textarea 
                      placeholder="about the prospect in brief "
                      className='py-2 px-3 text-sm font-medium rounded border border-gray-300' 
                      onChange={(e) => setAbout(e.target.value)}
                      value={about} 
                      required
                    ></textarea>
                  
                    <input type="text" 
                    placeholder='Fathers name' 
                    className='py-2 px-3 text-sm font-medium rounded border-b-2 border-gray-300' 
                    onChange={(e)=>setFname(e.target.value)}
                    value={fname}
                    required
                    />
                    {errors && <p className="text-xs text-red-500 font-bold">{errors.fname}</p>}
                    <input type="text" 
                    placeholder='Mothers name' 
                    className='py-2 px-3 text-sm font-medium rounded border-b-2 border-gray-300' 
                    onChange={(e)=>setMname(e.target.value)}
                    value={mname}
                    required
                    />
                    {errors && <p className="text-xs text-red-500 font-bold">{errors.mname}</p>}
                    <select
                    className='py-2 px-3 text-sm font-medium rounded border-b-2 border-gray-300' 
                    onChange={(e)=>setFoccu(e.target.value)}
                    value={foccu}
                    required
                    >
                      <option value="" className="text-gray-300">--select father's occupation--</option>
                      {designations.map((item)=>(
                         <option key={item.id} value={item.value} className="text-gray-500">{item.value}</option>
                      ))}
                    </select>
                    {errors && <p className="text-xs text-red-500 font-bold">{errors.foccu}</p>}
                    <select
                    className='py-2 px-3 text-sm font-medium rounded border-b-2 border-gray-300' 
                    onChange={(e)=>setMoccu(e.target.value)}
                    value={moccu}
                    required
                    >
                      <option value="" className="text-gray-300">--select mother's occupation--</option>
                      {designations.map((item)=>(
                         <option key={item.id} value={item.value} className="text-gray-500">{item.value}</option>
                      ))}
                    </select>
                    {errors && <p className="text-xs text-red-500 font-bold">{errors.moccu}</p>}
                    <textarea 
                      placeholder="about the family in brief "
                      className='py-2 px-3 text-sm font-medium rounded border border-gray-300' 
                      onChange={(e) => setFabout(e.target.value)}
                      value={fabout} 
                      required
                    ></textarea>
                    {errors && <p className="text-xs text-red-500 font-bold">{errors.aboutFamily}</p>}
                  {!isPending && <button onClick={handleSubmit} className='py-3 px-4 bg-themed3 text-themel4 rounded-lg'>Submit</button>}
                  {isPending && <div className='fixed left-0 right-0 top-0 bottom-0 bg-themed4 opacity-75 flex items-center justify-center'>
                     <div className='bg-white p-2' >
                         <img src={logo} className="w-16 h-16 animate-spin mb-2" /> 
                         <p className='text-themed3 text-sm font-bold'>Uploading your match, Please wait....</p>
                     </div>
                  </div>
                  }
                  
                 </form>

                
                
           </div>   
          
    </div>
 </div>
   )

}
