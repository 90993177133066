import axios from "axios"
import { useState } from "react"
import { useActionData } from "react-router-dom"
import { makeRequest } from "../axios"
import { useAuthContext } from "../hooks/useAuthContext"
import logo from "../images/logo192.png"


export default function ForgotPassword() {
    const [email, setEmail] = useState()
    const [display, setDisplay] = useState('none')
    const [error,setError] = useState('')
    const [emailError, setEmailError] = useState('')
    const {fgotpw, isPending,success} = useAuthContext()

    const handleOnBlur = (e)=>{
       if(e.target.value == ''){
          setEmailError('enter your email')
       }
     
    }

    const input = {
        email: email
    }

    const handleSubmit =  (e)=>{
        e.preventDefault()
        fgotpw(input)
        setEmail('')
    }
 
  return (
        <div className=' min-h-screen bg-themel3 flex items-center text-center -z-50 pt-10 pb-20'>
            <div className=' max-w-sm mx-auto flex-1 space-x-2 flex flex-col items bg-themed1 center p-3 overflow-auto rounded-xl '>
                <p className="text-2xl font-bold text-themel3 pb-8 ">Forgot Password</p> 
              
                <p className=" p-2 bg-green-100 text-xs m-3 rounded-lg text-green-600" style={{ display: display}}>An email with a link to reset your password has been sent successfully</p>
                   
                <form   className='flex flex-col space-y-4'>
                   {!success  && 
                      <input type="email"
                      placeholder='enter your email' 
                      className='py-2 px-3 text-sm font-bold rounded bg-themel4' 
                      onChange={(e)=>setEmail(e.target.value)}
                      onBlur={handleOnBlur}
                      required
                      />
                   } 
                  {emailError && <p className="text-xs text-red-500 font-bold">{emailError}</p>}      
                  {!isPending && 
                  <button onClick={handleSubmit} type="submit" className='py-3 px-4   bg-themed3 text-themel4 rounded-lg'>Submit</button>
                  }
                  {isPending && <div className='fixed left-0 right-0 top-0 bottom-0 bg-themed4 opacity-75 flex items-center justify-center'>
                  
                  <img src={logo} className="w-16 h-16 animate-spin mb-2" /> 
                  <p className='text-white text-sm font-bold'> Please wait....</p>
            
           </div>}
                  {error && <p className="text-xs font-bold text-red-600 ">{error}</p>}   
                 </form>       
            </div>
        </div>
  )
}
