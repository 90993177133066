export const occupations = [
    {
        id:"1",
        value:"State Government"
    },
    {
        id:"2",
        value:"Central Government"
    },
    {
        id:"3",
        value:"Public Sector Unit"
    },
    
    {
        id:"4",
        value:"Private"
    },
    {
        id:"5",
        value:"Public Sector Banks",
    },
    {
        id:"6",
        value:"Public Sector Insurance company",
    },
]