import { useEffect, useState } from "react"
import { AuthContext } from "../context/AuthContext"
import { makeRequest } from "../axios"
import { Link, matchPath, Navigate, useParams } from "react-router-dom"

import { Photourl } from "../Photourl"
import logo from "../images/logo512.webp"



export default function Profileview() {
    const {id} = useParams()
    const [udetails, setUdetails] = useState()
    const { photourl } = Photourl()
    const [isLoading,setIsLoading] = useState(false)
    const [match, setMatch] = useState()
   

     const input = {
        id
     }


      useEffect(()=>{
        async function go(){
            setIsLoading(true)
          await makeRequest.get(`/profileview/${id}`)
    
            .then((res)=>{
                setUdetails(res.data.user)
                setMatch(res.data.match)
              
            }) 
          setIsLoading(false)
        }
        go()
      },[])
   
      console.log(udetails)
      console.log(match)
   
  return (
   <div>
       {udetails  && (  
       <div className=" min-h-screen max-w-5xl mt-14 mx-auto p-4 flex flex-col items-center space-y-5">
            <img src={`${photourl}/avatars/${udetails.profile_pic}`} alt="" className=" w-28 h-28 rounded-full object-cover" />
            <h1 className="text-2xl font-bold text-themed3 ">{udetails.family_name}{" "}{udetails.first_name}{" "}{udetails.last_name}</h1>
            <h2>Email: {udetails.email}</h2>
            <h2>Mobile: {udetails.mobile}</h2>
            <h2>Address: {udetails.address}</h2>
            <h2>City: {udetails.city}</h2>
            <h2>Diocese: {udetails.diocese_name}</h2>
            <h2>Church name: {udetails.church_name}</h2>
            
            <p className="text-xl font-bold text-themed2 font-serif"> <b>{udetails.first_name}</b> <b>{udetails.last_name}</b>  is looking matches for</p>
      
      </div>
      
      )}

      {match && (
         

          <>  
        
         <Link to={`/likedmatch/${match.id}`}><div  className="flex items-center space-x-2 px-4 py-2 bg-themel2 rounded-lg">
                     <img src={`${photourl}/bust/${match.bust_image}`} className=" w-7 h-7 rounded-full"/>
                     <p className="text-sm font-bold text-themed3 ">{match.first_name}</p>
                     <p className="text-sm font-bold text-themed3 ">{match.last_name}</p>      
                 </div></Link>
         </>
      )}
      {isLoading && (
           <div className='fixed left-0 right-0 top-0 bottom-0 bg-themed4 opacity-75 flex items-center justify-center'>
         
               <img src={logo} className="w-16 h-16 animate-spin mb-2" /> 
               <p className='text-white text-sm font-bold'>, Please wait....</p>
         
        </div>
      )}
   </div>
   
         

  )
}
