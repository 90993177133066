
import { useState } from "react"
import { Link, Navigate, useNavigate } from "react-router-dom"
import { useAuthContext } from "../hooks/useAuthContext"
import logo from "../images/logo192.png"




export default function Login() {
   const [email, setEmail] = useState()
   const [password, setPassword] = useState()
   const {login,isPending, errors, loginsuccess, token, loginerror,emverify} = useAuthContext()


   const inputs = {
    email:email,
    password:password
   }

   const handleSubmit= (e)=>{
    e.preventDefault()
      login(inputs)
   }
  
  if(!loginsuccess){
     return (
      <div className=' min-h-screen bg-themel3 flex items-center text-center -z-50 pt-10 pb-20'>
      <div className=' max-w-sm mx-auto flex-1 space-x-2 flex flex-col items bg-themed1 center p-3 overflow-auto rounded-xl '>
          <p className="text-2xl font-bold text-themel3 pb-8 ">CSIMarital</p> 
        
           <form onSubmit={handleSubmit}  className='flex flex-col space-y-4'>
           <input type="email"
               placeholder='email' 
               className='py-2 px-3 text-sm font-bold rounded bg-themel4' 
               onChange={(e)=>setEmail(e.target.value)}
               required
               />
              {errors && <p className="text-xs text-red-400">{errors.email}</p>} 
           <input type="password" 
               placeholder='password' 
               className='py-2 px-3 text-sm  font-bold rounded bg-themel4' 
               onChange={(e)=>setPassword(e.target.value)}
               />  
                {errors && <p className="text-xs text-red-400">{errors.password}</p>} 
                  {!isPending && <button onClick={handleSubmit} type="submit" className='py-3 px-4   bg-themed3 text-themel4 rounded-lg'>Login</button>}
                   {isPending &&  <div className='fixed left-0 right-0 top-0 bottom-0 bg-themed4 opacity-75 flex items-center justify-center'>
                  
                  <img src={logo} className="w-16 h-16 animate-spin mb-2" /> 
                  <p className='text-white text-sm font-bold'> Please wait....</p>
            
           </div> }
                  {loginerror && <p className="text-xs text-red-400">{loginerror}</p>}
           </form>
             <div className="flex items-center justify-between">
                <Link to="/register"><p className="text-xs font-bold text-themed4 p-3">Signup</p></Link>
                <Link to="/fpassword"><p className="text-xs font-bold text-themed4 p-3">Forgot password</p></Link>
             </div>
      </div>
   </div>  
     )
  }else 
  return (
     <Navigate to="/allmatches" />
  )
}
