import logoImg from "../images/csim1.png"
import bgimg from "../images/bgcsim.png"


export default function Header() {
  return (
    <div className='w-full bg-themeblue mt-6 relative -z-10' style={{ maxHeight:'450px'}}>
      <div className="  md:flex md:items-center justify-center md:space-x-7 " >
            <img src={bgimg} alt="" className="" style={{ maxHeight:'450px'}} />
            <div className="hidden md:block  flex flex-col items-center justify-end space-y-3">
            <h1 className=" text-7xl font-bold text-themel3 shadow-md leading-6 mb-8">CSIMarital</h1>
               <hr className="text-xl font-bold text-themel4 "/>
               <p className="text-xl font-bold text-themel3">Matrimonial Services</p>
            </div>
            
      </div> 
     
    </div>
    
  )
}
