import { useState } from "react"
import { Link } from "react-router-dom"
import { useAuthContext } from "../hooks/useAuthContext"
import logo from "../images/logo512.png"
import { Photourl } from "../Photourl"
import dummy from "../images/dummy.webp"


export default function Navbar() {
    const [menuOpen, setMenuOpen] = useState(false)
    const {currentUser,token, isPending, errors, logout} = useAuthContext()
    const {photourl} = Photourl()
   
      const inputs ={
        token:token
      }
    
   const handleClick = (e)=>{
    e.preventDefault()
      logout(inputs)
      setMenuOpen(false)
   }

 
        
      
  return (
    <div className="fixed w-full top-0  bg-themel4 z-50">
    <div className=" max-w-7xl mx-auto p-3 flex items-center justify-between">
    <Link to="/"><img src={logo} alt="" className=" w-24 h-12 cursor-pointer"/></Link>
      <div className="hidden sm:flex gap-3">
     
         {currentUser ? 
           currentUser.razor_payment?
          <>
             <Link to="/allmatches"><p className="text-xl font-bold text-themed4 hover:text-themel1 cursor-pointer">Browse matches</p></Link>
             {currentUser.profile_pic && <img src={`${photourl}/avatars/${currentUser.profile_pic}`} alt="" className=" w-6 h-6 rounded-full" />}
             {!currentUser.profile_pic && <img src={dummy} alt="" className=" w-6 h-6 rounded-full" />}
            <p className="text-lg font-medium text-themed4 hover:text-themel1 cursor-pointer">{currentUser.first_name} {currentUser.last_name}</p>
            
             <Link to="/profile"><p  className="text-lg font-medium text-themed4 hover:text-themel1 cursor-pointer">My Account</p></Link>
            
            {!isPending && <button onClick={handleClick}  className="px-3 py-1 rounded-md text-sm bg-themed3 hover:bg-themel3 font-bold text-themel3 hover:text-themed3 cursor-pointer">Logout</button>} 
            {isPending && <button onClick={handleClick}  className="px-3 py-1 rounded-md text-sm bg-themed3 hover:bg-themel3 font-bold text-themel3 hover:text-themed3 cursor-pointer">Logging you out ....</button>} 
           </>
           :
           <>
            <img src={`${photourl}/avatars/${currentUser.profile_pic}`} alt="" className=" w-6 h-6 rounded-full" />
            <p className="text-lg font-medium text-themed4 hover:text-themel1 cursor-pointer">{currentUser.first_name} {currentUser.last_name}</p>
            
            <Link to="/profile"><p onClick={()=> setMenuOpen(false)} className="text-lg font-medium text-themed4 hover:text-themel1 cursor-pointer">My Account</p></Link>
            
            {!isPending && <button onClick={handleClick}  className="px-3 py-1 rounded-md text-sm bg-themed3 hover:bg-themel3 font-bold text-themel3 hover:text-themed3 cursor-pointer">Logout</button>} 
            {isPending && <button onClick={handleClick}  className="px-3 py-1 rounded-md text-sm bg-themed3 hover:bg-themel3 font-bold text-themel3 hover:text-themed3 cursor-pointer">Logging you out ....</button>} 
           </>
           :
           <>
            <Link to="/register"><p className="text-xl font-bold text-themed4 hover:text-themel1 cursor-pointer">Register</p></Link>
          <Link to="/login"><button  className="px-3 py-1 rounded-md text-sm bg-themed3 hover:bg-themel3 font-bold text-themel3 hover:text-themed3 cursor-pointer">Login</button></Link>
           </>
        }

      </div>
        {!menuOpen && (
           <div onClick={()=> setMenuOpen(!menuOpen)} class="space-y-1 md:hidden cursor-pointer z-20">
           <div className="w-6 h-0.5 bg-black"></div>
           <div className="w-6 h-0.5 bg-black"></div>
           <div className="w-6 h-0.5 bg-black"></div>
         </div>
        )}

        {menuOpen && (
           <div onClick={()=> setMenuOpen(!menuOpen)} class=" md:hidden cursor-pointer z-20">
           <div className=" text-xs font-bold text-white">close</div>
         
           
         </div>
        )}
         
          {menuOpen && (
             <div id="menu" className=" flex flex-col items-center justify-center space-y-4 bg-themed3 min-h-max absolute left-0 top-0 w-full p-10  rounded-b-3xl  text-themel4  text-center ease-in ">
                
             
        
                {currentUser ? 
           currentUser.razor_payment?
          <>
             <Link to="/allmatches"><p onClick={()=>setMenuOpen(false)} className="text-xl font-bold text-white hover:text-themel1 cursor-pointer">Browse matches</p></Link>
             <img src={`${photourl}/avatars/${currentUser.profile_pic}`} alt="" className=" w-6 h-6 rounded-full" />
             <Link to="/profile"><p onClick={()=>setMenuOpen(false)}   className="text-lg font-medium text-white hover:text-themel1 cursor-pointer">{currentUser.first_name} {currentUser.last_name}</p></Link>
            
            <Link to="/profile"><p onClick={()=>setMenuOpen(false)} className="text-lg font-medium text-white hover:text-themel1 cursor-pointer">My Account</p></Link>
            
            {!isPending && <button onClick={handleClick}  className="px-3 py-1 rounded-md text-sm bg-themel1 hover:bg-themel3 font-bold text-themed3 hover:text-themel3 cursor-pointer">Logout</button>} 
            {isPending && <button onClick={handleClick}  className="px-3 py-1 rounded-md text-sm bg-themel1 hover:bg-themel3 font-bold text-themed3 hover:text-themel3 cursor-pointer">Logging you out ....</button>} 
           </>
           :
           <>
             <img src={`${photourl}/avatars/${currentUser.profile_pic}`} alt="" className=" w-6 h-6 rounded-full" />
            <p className="text-lg font-medium text-white hover:text-themel1 cursor-pointer">{currentUser.first_name} {currentUser.last_name}</p>
            
            <Link to="/profile"><p onClick={()=>setMenuOpen(false)} className="text-lg font-medium text-white hover:text-themel1 cursor-pointer">My Account</p></Link>
            
            {!isPending && <button onClick={handleClick}  className="px-3 py-1 rounded-md text-sm bg-themel1 hover:bg-themel3 font-bold text-themed3 hover:text-themel3 cursor-pointer">Logout</button>} 
            {isPending && <button onClick={handleClick}  className="px-3 py-1 rounded-md text-sm bg-themel1 hover:bg-themel3 font-bold text-themed3 hover:text-themel3 cursor-pointer">Logging you out ....</button>} 
           </>
           :
           <>
            <Link to="/register"><p onClick={()=>setMenuOpen(false)} className="text-xl font-bold text-white hover:text-themel4 cursor-pointer">Register</p></Link>
          <Link to="/login"><button onClick={()=>setMenuOpen(false)}  className="px-3 py-1 rounded-md text-sm bg-themed3 hover:bg-themel3 font-bold text-white hover:text-themel4 cursor-pointer">Login</button></Link>
           </>
        }
         
         </div>
          )}
          

    </div>
</div>
  )
}

