import { useEffect, useState } from "react"
import { AuthContext } from "../context/AuthContext"
import { makeRequest } from "../axios"
import { Link, Navigate, useParams } from "react-router-dom"
import axios from "axios"
import { Photourl } from "../Photourl"
import Profilemodal from "../components/Profilemodal"
import { useAuthContext } from "../hooks/useAuthContext"
import AddMatch from "../components/AddMatch"
import { useQuery } from "@tanstack/react-query";
import { format } from 'date-fns'
import logo from "../images/logo192.png"
import dummy from "../images/dummy.webp"



export default function Profile() {
    const [udetails, setUdetails] = useState()
    const { photourl } = Photourl()
    const [show,setShow] = useState(false)
    const {currentUser, token, setNotification, dispatch } = useAuthContext()
    const [modal,setModal] = useState(false)
   
   
    const date = new Date()
   
   const today = date.getFullYear() + '-' + (date.getMonth()+1) + '-'+ date.getDate()
   
 


    const handleClose =()=>{
        setShow(false)
      }

      const handleMatch = (e)=>{
        e.preventDefault()
 
        setModal(true)
     }
 
     const closeModal = (e)=>{
         e.preventDefault()
         setModal(false)
     }
  
    const handleClick = (e)=>{
        e.preventDefault()
        setShow(true)
       

      }


    const matchdata = {
        token:token
      }

   

      const { isLoading, error, data } = useQuery(['matches'], async ()=>{
        const res = await  makeRequest.post("/matches",matchdata)
        return res.data.matches
      })
   
  
      useEffect(()=>{
        async function go(){
          await makeRequest.post("/users", matchdata )
    
            .then((res)=>{
              dispatch({type:'ONCHANGE', payload:res.data.user })
              localStorage.setItem("user",JSON.stringify(res.data.user))
             

              if(!currentUser.matche){
                setNotification('You must add your own match in order to view others ')
              }else{
                setNotification('')
              }
          
            })  
       
        }
        go()
      },[])


 

    const expdate = format(new Date(currentUser.expiry), "yyyy-M-dd")

    
  return (
    <div>
        <div className=" min-h-screen max-w-5xl mt-14 mx-auto p-4 flex flex-col items-center space-y-5">
          {currentUser.profile_pic && <img src={`${photourl}/avatars/${currentUser.profile_pic}`} alt="" className=" w-28 h-28 rounded-full object-cover" />}
          {!currentUser.profile_pic && <img src={dummy} alt="" className=" w-28 h-28 rounded-full object-cover" />}
          <h1 className="text-2xl font-bold text-themed3 ">{currentUser.family_name}{" "}{currentUser.first_name}{" "}{currentUser.last_name}</h1>
          <h2>Email: {currentUser.email}</h2>
          <h2>Mobile: {currentUser.mobile}</h2>
          <h2>Address: {currentUser.address}</h2>
          <h2>City: {currentUser.city}</h2>
          <h2>Diocese: {currentUser.diocese_name}</h2>
          <h2>Church name: {currentUser.church_name}</h2>
          <button onClick={handleClick}  className="bg-themed2 text-white text-sm py-1 px-2 rounded-md">Update Profile</button>
            { currentUser.razor_payment && expdate > today  && <h2>Subscription up to: {format(new Date(currentUser.expiry), "dd-MM-yyyy")}</h2>} 
            { currentUser.razor_payment && expdate < today && <Link to="/rpayment"><button   className="bg-themed2 text-white text-sm py-1 px-2 rounded-md">Renew Subscription</button></Link>} 
            { !currentUser.razor_payment && <Link to="/payment"><button   className="bg-themed2 text-white text-sm py-1 px-2 rounded-md">Buy Subscription</button></Link>} 

          {!currentUser.matche && <button onClick={handleMatch}  className="bg-themed2 text-white text-sm py-1 px-2 rounded-md">Add a Match</button>}
          <p className="text-xl font-bold text-themed2 font-serif"> Matches posted under your account </p>
          
          {data && data.map((item)=>(
             <Link to={`/matchuserview/${item.id}`}><div  key={item.id} className="flex items-center space-x-2">
                <img src={`${photourl}/bust/${item.bust_image}`} className=" w-7 h-7 rounded-full"/>
                  <p className="text-sm font-bold text-themed3 ">{item.first_name}</p>
                  <p className="text-sm font-bold text-themed3 ">{item.last_name}</p>      
             </div></Link>
          ))}
             
            

             

    </div>  
    {show && (
       <Profilemodal handleClose={handleClose} setShow={setShow}  udetails={currentUser} token={token} />  
      )}
      {modal && (
        <AddMatch closeModal={closeModal} user={currentUser} setModal={setModal}/>
      )}

    </div>
  )
}
