export const Cadres = [
    {
        id:"1",
        value:"Clerical"
    },
    {
        id:"2",
        value:"Supervisor"
    },
    {
        id:"3",
        value:"Grade1 Officer"
    },
    {
        id:"4",
        value:"Above Grade1 officer"
    },
    {
        id:"5",
        value:"Manager"
    },
    {
        id:"6",
        value:"CEO"
    },
    {
        id:"7",
        value:"Others"
    },
]