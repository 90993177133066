import { Link } from "react-router-dom"
import { Photourl } from "../Photourl"
import dummy from "../images/dummy.webp"
import { format } from 'date-fns'


export default function MatchList({matches}) {
   const mats = Object.values(matches)
   const {photourl} = Photourl()
   const today = new Date().toLocaleDateString('en-CA')
   const list = mats.filter(mat=>mat.user.expiry > today )
  return (
    <div className=" max-w-6xl min-h-screen mx-auto  grid grid-cols-1 md:grid-cols-3 lg:grid-cols-4 gap-4 ">
        {list && list.map((match, index)=>(
            <Link to={`/matches/${match.id}`}>
            <div key={index} className="flex flex-col items-center">
               {match.bust_image && <img src={`${photourl}/bust/${match.bust_image}`} alt="" className=" md:w-40 md:h-44"/>}
               {!match.bust_image && <img src={dummy} alt="" className=" md:w-40 md:h-44"/>}
               <h2 className="text-sm font-bold text-themed4">{match.first_name} {match.last_name} {match.family_name}</h2>
               <p>{match.date_of_birth}</p>
               <p>{match.occupation}</p>
               <p>{match.Organization}</p>
               <p>{match.place_of_work}</p>
            </div>
            </Link>
           ))}
    </div>
  )
}
